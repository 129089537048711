<template>
  <b-sidebar
    id="test-link-account-api-sidebar"
    body-class="custom-scroll"
    v-model="showTestLinkAccountAPISideBar"
    aria-labelledby="Test Link Account API"
    aria-label="Test Link Account API"
    shadow
    bg-variant="white"
    :backdrop-variant="`dark`"
    no-close-on-esc
    no-close-on-backdrop
    lazy
    backdrop
    right
    width="50vw"
  >
    <template #header="{ hide }">
      <h3 class="font-21 theme-font-medium mb-0">Test API</h3>
      <div class="sidebar-header-close">
        <a
          href="javascript:void(0)"
          role="button"
          aria-labelledby="Test API title"
          @click="
            hide;
            $emit('closeSideBar');
          "
          ><img src="../../../assets/img/close.png" alt="close-tab"
        /></a>
      </div>
    </template>
    <template #default="{}">
      <b-row>
        <b-col>
          <h4 class="page-sub-header">Configuration</h4>
          <TestLinkAccountAPIForm :data="data" :linkAccountDetails="linkAccountDetails" />
        </b-col>
      </b-row>
    </template>
    <template #footer="{ hide }">
      <b-button
        type="button"
        v-activeBlur
        class="btn-h-44"
        variant="outline-secondary"
        @click="
          hide;
          $emit('closeSideBar');
        "
        >Close</b-button
      >
    </template>
  </b-sidebar>
</template>
<script>
export default {
  name: 'TestLinkAccountAPISideBar',
  props: ['showTestLinkAccountAPISideBar', 'data', 'linkAccountDetails'],
  components: {
    TestLinkAccountAPIForm: () => import('./TestLinkAccountAPIForm.vue')
  }
}
</script>
